document.addEventListener('DOMContentLoaded', () => {
  const dropdowns = document.querySelectorAll('[data-thq="thq-dropdown"]');

  dropdowns.forEach((dropdown) => {
    const menu = dropdown.querySelector('[data-thq="thq-dropdown-list"]');
    let hideTimeout;

    if (menu) {
      // Menü öffnen
      dropdown.addEventListener('mouseenter', () => {
        clearTimeout(hideTimeout); // Vorherigen Timeout abbrechen
        menu.style.display = 'block'; // Menü sichtbar machen
        menu.style.position = 'absolute'; // Sicherstellen, dass es überlagert
        menu.style.top = `${dropdown.offsetHeight}px`; // Position unter dem Toggle
        menu.style.left = '0'; // Ausrichten
      });

      // Menü verzögert schließen
      dropdown.addEventListener('mouseleave', () => {
        hideTimeout = setTimeout(() => {
          menu.style.display = 'none'; // Menü verstecken
        }, 500); // Verzögerung in Millisekunden
      });
    }
  });
});
